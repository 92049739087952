.logo {
    justify-content: flex-start;
    max-width: 150px;
    max-height: auto;
    object-fit: contain;
    transition: transform .2s ease;
    margin-top: 20px;
    margin-left: 30px;
    z-index: 10000;
    position: relative;
}

.the-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: var(--textColour);
    z-index: 6;
    background-color: transparent;
    position: fixed;
    z-index: 9000;
    padding-bottom: 0.7rem;
}
.the-header.active{
    background-color: var(--lightGrey);
}


.langButtons {
    margin-top: 10px;
    display: flex;
    background-color: var(--textColour);
    padding: 20px;
    width: 40px;
    height: 40px;
    color: #ffffff;
    text-align: center;
    justify-content: space-evenly;
    align-items: center;
    border: 4px double #000000;
    border-radius: 10px;
    font-size: 20px;
    cursor: pointer;
    transition: transform .5s ease;
}

.langButtons:hover {
    background-color: #d3d3d3;
    transform: scale(1.1);
    color: black;
  }

nav a{
    margin: 0 1.7rem;
    color: var(--textColour);
    text-decoration: none;
    text-align:right;
    transition: transform .3s ease;
    font-weight: normal;
    margin-top: 15px;
}

nav a:hover{
    color: var(--secondaryColor);
    transform: scale(1.1);
    text-decoration: underline;
}

nav p{
    margin-left: 25px;
    margin-right: 25px;
}



.logo:hover {
    transform: scale(1.3);
}

.nav-btn {
    padding-top: 4px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    background: white;
    border: 4px double #000000;
    outline: 0.5px solid black;
    color: var(--textColour);
    visibility: hidden;
    opacity: 0;
    font-size: 1.8rem;
    margin-right: 1rem;
    border-radius: 5px;
}

nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    padding-top: 15px;
}



.dropdown-container {
    position: relative;
    padding-top: 15px;
    transition: transform .5s ease;
}
  
.dropdown-container:hover {
    color: var(--secondaryColor);
    transform: scale(1.1);
    text-decoration: none;
}

.service-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    display: grid;
    align-items: center;
    justify-content: center;
    text-align: center; /* Added for horizontal text centering */
    z-index: 998;
    font-size: 15px;
    color: #000000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    background-color: white;
    padding-bottom: 15px;
    outline: 0.5px solid black;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.7);
    z-index: 999; /* You had two z-indexes, so I removed one */
}

.service-dropdown.big {
    padding-right: 10px;
}

.service-dropdown.transparent {
    background-color: transparent;
    outline: 0px solid black;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
}

.dropdown-container:hover .service-dropdown {
    opacity: 1;
    visibility: visible;
    text-decoration: #d3d3d3;
}



@media only screen and (max-width: 1024px) {

    .service-dropdown {
        padding-right: 20px;
    }

    nav p{
        font-size: 1.5rem;
    }

    .service-dropdown.transparent {
        background-color: white;
        outline: 0.5px solid black;
        box-shadow: 0px 4px 8px rgba(0, 0, 0, 0);
    }

    header .nav-btn {
        visibility: visible;
        opacity: 1;

    }
    header nav {
        position: fixed;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        background-color: white;
        transition: 1s;
        transform: translateY(-100vh);
        z-index: 997;
    }
    header .responsive_nav {
        transform: none;
    }
    nav .nav-close-btn {
		position: absolute;
		top: 2rem;
		right: 2rem;
	}

	nav a {
		font-size: 1.5rem;
	}

    .logo {
        margin-top: 15px;
        margin-left: 20px;
        max-width: 120px;
    }
}

@media only screen and (max-width: 412px) {
    .logo {
        margin-top: 15px;
        margin-left: 20px;
        max-width: 70px;
    }
}