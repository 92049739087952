/*
.mapsContainer {
    display: flex;
    padding: 10%;
    background-color: var(--lightGrey);
    
}

.map {
    border-radius: 3%;
    margin-right: 2%;
}

.mapText {
    flex-direction: row;
    font-size: 1.2rem;
    color: var(--textColour);
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 4%;
}

.mapText hr {
    border-top: 1px black #ccc;
    margin: 1rem 0;
    margin-bottom: 20px;
}

.mapText.Bold {
    font-weight: bold;
    flex-direction: row;
    font-size: 2rem;
}

@media only screen and (max-width: 1024px) {
    .mapContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}*/
.mapsContainer {
    display: flex;
    flex-direction: column; /* Change to column on mobile screens */
    align-items: center; /* Center align on mobile */
    padding: 10%;
    padding-top: 13%;
    background-color: var(--lightGrey);
}

.map {
    border-radius: 3%;
    margin-right: 2%;
    max-width: 400px;
}

.mapText {
    font-size: 1.2rem;
    color: var(--textColour);
    align-items: center;
    text-align: center;
    justify-content: center;
    padding: 4%;
}

.mapText hr {
    border-top: 1px black #ccc;
    margin: 1rem 0;
    margin-bottom: 20px;
}

.mapText.Bold {
    font-weight: bold;
    font-size: 2rem;
}

@media only screen and (min-width: 1024px) {
    .mapsContainer {
        flex-direction: row; /* Revert to row on larger screens */
        justify-content: space-between;
        padding-top: 10%;
    }
    .map {
        max-width: 10000px;
    }
}


