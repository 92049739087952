.HomeimageContainer {
    position: relative;
    width: 100%;
    z-index: 4;
    background-color: white;
}

.imageTextContainer {
    position:relative;
    transition: transform 1s ease-in-out;
}

.HomeImage {
    width: 100%;
    height: auto;
  }
  
  .textContainer {
    position: absolute;
    top: 10%;
    left: 0;
    color: white;
    font-weight: bold;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    z-index: 9999;
  }
  
  .textContainer.right {
    left: auto;
    right: 0;
  }
  
  .summary {
    font-size: 2rem;
    text-align: center;
  }
  


.homeoverlay-text {
    padding: 0rem;
    position: absolute;
    top: 20%;
    left: -0%;
    color: white;
    font-size: 2.7rem;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    line-height: 1;
    z-index: 0;
}
.homeoverlay-text:hover {
    transform: scale(1.05);
}
.homeoverlay-text.one {
    top: 25%;
    margin-left: 20px
}
.homeoverlay-text.high {
    top: 10%;
    margin-left: 20px
}
.homeoverlay-text.mid {
    top: 25%;
    margin-left: 20px
}
.homeoverlay-text2 {
    padding-top: 0rem;
    position: absolute;
    top: 70rem;
    left: -0%;
    color: white;
    font-size: 2.7rem;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    line-height: 1;
    
}
.homeoverlay-text2:hover {
    transform: scale(1.05);
}
.homeoverlay-text3 {
    padding-top: 0rem;
    position: absolute;
    top: 70rem;
    left: -0%;
    color: white;
    font-size: 2.7rem;
    font-weight: bold;
    text-align: center;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
    line-height: 1;
}
.homeoverlay-text3:hover {
    transform: scale(1.05);
}


.SloganContainer {
    padding: 3rem 3rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    box-shadow: 0px 0px 10px var(--textColour);
}

.Slogan {
    flex: 1;

}

.HeadSlogan {
    text-align: center;
    padding: 2%;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.2;
    margin-bottom: 20px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
    color: var(--textColour);
}

.HeadSlogan:hover {
    transform: scale(1.05);
    text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.2);
}

.SloganImage {
    max-width: 500px;
    height: auto;
    border-radius: 30px;
    border: 1px solid var(--textColour);
    box-shadow: 0 4px 8px rgba(0,0,0,0.5);
    object-fit: cover;
    transition: transform .3s ease;
    margin-left: 1rem;
    margin-bottom: 1rem;
}

.SloganImage:hover {
    transform: scale(1.05);
}


@media only screen and (max-width: 1024px) {
    .homeoverlay-text {
        padding: 1rem;
        position: absolute;
        top: 20%;
        left: -0%;
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease;
        
    }
    .homeoverlay-text:hover {
        transform: scale(1.05);
    }
    .textContainer {
        top: 20%;
        font-size: 1rem;
      }
    .homeoverlay-text2 {
        padding: 1rem;
        position: absolute;
        top: 80%;
        left: -0%;
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease;
        
    }
    .homeoverlay-text2:hover {
        transform: scale(1.05);
    }
    .homeoverlay-text3 {
        padding: 1rem;
        position: absolute;
        top: 60%;
        left: -0%;
        color: white;
        font-size: 1.4rem;
        font-weight: bold;
        text-align: center;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        transition: all 0.3s ease;
        
    }
    .homeoverlay-text3:hover {
        transform: scale(1.05);
    }
    .SloganContainer {
        flex-direction: column; /* Stack items vertically */
        align-items: center; /* Center items horizontally */
        padding: 1rem;
    }

    .Slogan {
        text-align: center;
        margin-bottom: 1rem;
    }

    .HeadSlogan {
        text-align: center;
        padding: 2%;
        font-size: 1.2rem;
    }

    .SloganImage {
        max-width: 400px; /* Reduce image width for mobile */
        /* ...rest of the styles will be inherited from the original class */
    }
}